.form-process{
	position: absolute;
	top: 0;
	left:0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	
	i{
		font-size: 60px;
		height: 60px;
		width: 60px;
	}
	&:before {
	  content: "";
	  display: inline-block;
	  vertical-align: middle;
	  height: 100%;
	}
  	.content{
  		display: inline-block;
  		vertical-align: middle;
  		text-align: center;
  	}
}

.spinner{
	&:before{ 
		animation: spin 2s linear infinite;
		display: inline-block;
	}
}

@keyframes spin {
  0% {
        transform: rotate(0deg);
  }
  100% {
        transform: rotate(-359deg);
  }
}