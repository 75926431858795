.all-centres{
	.centre-formation{
		figure{
			float: left;
			max-width: 50%;
			img{
				position: relative;
				max-width: 100%;
			}
		}
		.centre-content{
			padding: 10px;
		}
		.description{
			color:  $violet-principal;
			&::before{
				content:"";
				display:table;
				clear:both;
			}
		}
	}
}
.fiche-centre{
	h1{
		margin-top: 0px;
	}
	.img-centre-formation{
		max-height: 130px;
	}
	.container-img-centre {
		text-align: center;
	}
}

/*========== Mobile First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
	.fiche-centre{
		.img-centre-formation{
			display: inline;
		}
	}
}


/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
	.fiche-centre{
		.img-centre-formation{
			display: block;
			float: left;
			margin-right: 20px;
			max-width: 250px;
		}
	}
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
	// DESKTOP
	.all-centres{
		&::after{
			content: '';
			display: table;
			clear: both;
		}
		.cadre-centre-formation{
			float:left;
			width: 50%;
			padding : 5px;
		}
		.centre-content{
			display: block;
		}
		.centre-formation{
			border: 1px solid $violet-principal;
			display: table;
			text-align: center;
			.table{
				display: table;
				margin-bottom: 0px;

			}
			figure{
				max-width: 100%;
				width: 100%;
				text-align: center;
				float: none;
				height: 120px;
				display: table-cell;
				vertical-align: middle;
				img{
					margin-left: auto;
					margin-right: auto;
					max-height: 120px;
				}
			}
			h2{
				display: none;
			}
			.btn{
				width: 50%;
			}
			.description{
				margin-bottom: 5px;
			}
		}
	}
	.formations-ecole{
		.formation{
			width: 50%;
			float: left;
		}
	}
	img{
		&.animadis{
			max-height: 130px;
		}
	}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
	.all-centres{
		.cadre-centre-formation{
			width: 33.33%;
		}
	}
}
