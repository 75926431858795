
/*========== Mobile First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
	
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
	// DESKTOP
	
	.tree{
		position: relative;
		margin-left: 30px;
		&:before{
			content: '';
			display:block;
			border-left: 2px dotted $violet-principal;
			position:absolute;
			left:-30px;
			top: 15px;
			bottom:0;
			width: 15px;
			height: 100%;
		}
	}
	.tree-title{
		span{
			
			position: relative;
		}
		
		&:before{
			border-top: 2px dotted $violet-principal;
		}
		&:nth-last-of-type(1){
			&:before{
				border-left: none;
			}
		}
	}
	.tree-item{
		&:nth-last-of-type(1){
			&:before{
				border-left: none;
			}
		}
	}
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}

