/*---------------*/
/***** Oscar *****/
/*---------------*/

figure.effect-oscar {
	background: -webkit-linear-gradient(45deg, white 0%, grey 40%, white 100%);
	background: linear-gradient(45deg, white 0%, grey 40%, white 100%);
}

figure.effect-oscar img {
	opacity: 0.9;
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;
}

figure.effect-oscar figcaption {
	padding: 1em;
	background-color: rgba(58,52,42,0.7);
	-webkit-transition: background-color 0.35s;
	transition: background-color 0.35s;
}

figure.effect-oscar figcaption::before {
	position: absolute;
	top: 30px;
	right: 30px;
	bottom: 30px;
	left: 30px;
	border: 1px solid #fff;
	content: '';
}

figure.effect-oscar h2 {
	margin: 20px 0 10px 0;
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(0,10%,0);
	transform: translate3d(0,10%,0);
	font-size: 20px;
}

figure.effect-oscar figcaption::before,
figure.effect-oscar p {
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(0);
	transform: scale(0);
}

figure.effect-oscar:hover h2 {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

figure.effect-oscar:hover figcaption::before,
figure.effect-oscar:hover p {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

figure.effect-oscar:hover figcaption {
	background-color: rgba(58,52,42,0);
}

figure.effect-oscar:hover img {
	opacity: 0.4;
}
