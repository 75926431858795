.table-matiere-faq{
	margin-bottom: 10px;
	.categorie{
		margin-bottom: 10px;
	}
	.categorie-titre{
		color : #222;
		font-size: 16px;
	}
	.questions{
		a{
			color: $violet-principal;
			display: block;
		}
	}
}
.faq-categories{
	padding: 10px 0px;
	h2{
		font-size:24px;
		margin-bottom: 5px;
	}
	h3{
		margin:0;
	}
	.question{
		display: block;
		width: 100%;
		padding: 10px 10px 10px 10px;
		position: relative;
		z-index: 5;
	}
	.reponse{
		position: relative;
		display: block;
		width: 100%;
		padding : 0px;
	}
	
}
.description-faq{
	a{
		display: block;
		color: black;
	}
}
.ask-icon{
	padding-left: 30px;
	position: relative;
	display: block;
	width: 100%;
	&::before{
		content: '\e063';
		width: 20px;
		height: 20px;
		position: absolute;
		font-family: $font-entypo;
		color: $violet-principal;
		top: 10px;
		left: 0px;
		border-radius: 50%;
		font-size: 22px;
		padding-top: 3px;
	}
}

/*========== Mobile First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
	
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
	// DESKTOP
	.faq-categories{
		padding: 20px 0px;
		.question{


			padding: 10px 10px 10px 40px;
			&::before{
				width: 30px;
				height: 30px;
				background: $violet-principal;
				color: white;
				top: 10px;
				left: 5px;
				padding-left: 4px;
				padding-top: 3px;
			}
		}
		.reponse{
			padding : 10px 10px 10px 70px;
		}
		
	}
	.ask-icon{
		padding-left: 40px;
		position: relative;
		display: block;
		width: 100%;
		&::before{
			width: 30px;
			height: 30px;
			background: $violet-principal;
			color: white;
			top: 0px;
			left: 5px;
			padding-left: 4px;
			padding-top: 3px;
		}
	}
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {

}
