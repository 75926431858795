.card-#{$name-card}{
	.top-card-titre{
		background: $color-card;
	}
	.top-card-items{
		ul{
			min-height: 170px;
			li{
				&:before{
					color: $color-card;
				}
			}
		}
	}
}
