.avertissement-cookie{
	background: $violet-principal;
	font-size: 10px;
	position: relative;
	color: white;
	padding: 5px;
	a{
		color: white;
		text-decoration: underline;
	}
	.ok{
		background: white;
		border: 1px solid $violet-principal;
		color: $violet-principal;
		margin-left: 10px;
		&:hover{
			background : $violet-principal;
			color: white;
			border-color: white;
		}
	}
}