section{
	&.fiche-formation{
		h1{
			margin-top: 0px;
		}
		section.media{
			display: none;
		}
		dl{
			margin: 0px;
			padding: 30px 0px;
		}
		dt{
			width: 100%;
			font-size: 16px;
			color: white;
			background: $violet-principal;
			padding: 6px;
		}
		dd{
			color: $violet-principal;
			padding: 0px 0px 20px 0px;
		}
		.description-fiche{
			dt{
				background: none;
				padding: 0px;
				cursor: pointer;
				span{
					padding: 6px;
					padding-left: 25px;
					position: relative;
					background: $violet-principal;
					display: block;
					margin-bottom: 10px;
					&:before{
						content: '.';
						font-family: $font-entypo;
						position: absolute;
						top: 2px;
						left: 0;
						font-size: 20px;
						color: white;
					}
				}
				&:nth-last-of-type(1){
					span{
						margin-bottom: 0px;
					}
				}
				&.open{
					& + dd{
						display: block;
					}
					
					span{
						&:before{
							content: ':';
						}
					}
				}
				
			}
			dd{
				display: none;
			}
		}
	}
}

/*========== Mobile First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
	
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
	// DESKTOP
	section{
		&.fiche-formation{
			h2{
				text-align: left;
				font-size: 22px;
				margin-top: 0px;
			}
			h5{
				text-align: left;
			}
			section.media{
				display: block;
			}
			.description-fiche{
				dt{
					span{
						display: inline-block;
					}
				}
			}
		}
	}
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
	section{
		&.fiche-formation{
			.en-bref{
				dl{
					
					padding-top: 0px;
				}
			}
		}
	}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}
