
/*========== Mobile First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
	
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
	// DESKTOP
	.cover{
		background-position: top center;
		background-repeat: no-repeat;
		background-image: url($path_img + "/cover/all.jpg");
	}

	.cover-animalerie{
		background-image: url($path_img + "/cover/all.jpg");
	}

	.cover-esthetique{
		background-image: url($path_img + "/cover/all.jpg");
	}

	.cover-comptabilite{
		background-image: url($path_img + "/cover/all.jpg");
	}

	.cover-design{
		background-image: url($path_img + "/cover/all.jpg");
	}

	.cover-droit{
		background-image: url($path_img + "/cover/all.jpg");
	}

	.cover-gestion{
		background-image: url($path_img + "/cover/all.jpg");
	}

	.cover-immobilier{
		background-image: url($path_img + "/cover/all.jpg");
	}

	.cover-paie-et-rh{
		background-image: url($path_img + "/cover/all.jpg");
	}

	.cover-paramedical{
		background-image: url($path_img + "/cover/all.jpg");
	}

	.cover-petite-enfance{
		background-image: url($path_img + "/cover/all.jpg");
	}

	.cover-photo{
		background-image: url($path_img + "/cover/all.jpg");
	}

	.cover-social{
		background-image: url($path_img + "/cover/dcg.jpg");
	}

	.cover-tourisme{
		background-image: url($path_img + "/cover/all.jpg");
	}

	.cover-vente{
		background-image: url($path_img + "/cover/all.jpg");
	}
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {

}


