$bg-title: #d8c5d8;
$bg-formation : #f8f3f9;
.formations-par-centre{
	padding: 10px 0;
	
}

/*========== Mobile First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
	
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
		
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
	.formations-par-centre{
		padding: 5px 0;
		h2{
			background: none;
			border-bottom: none;
			&:hover, &:active{
				padding-left: 10px;
			}
			&::after{
				display: none;
			}
		}
		.formations{
			display: block;
			.formation{
				background: none;
				float: left;
				width: 33.33%;
				padding: 5px;
				
				border-bottom: none;
				&:hover, &:active{
					padding-left: 5px;
				}
				a{
					padding: 10px;
					padding-right: 25px;
					background: $bg-formation;
					transition: padding-left 0.5s ease;
					min-height: 60px;
					&:hover, &:active{
						padding-left: 20px;
					}
					&:after{
						content: '.';
						position: absolute;
						font-family: $font-entypo;
						right: 10px;
						top: 20px;
						font-size: 18px;
					}
				}
				&:after{
					display: none;
				}
			}
		}
	}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}
