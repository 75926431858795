$bg-title: #d8c5d8;
$bg-formation : #f8f3f9;
section.all-sections{
	.header-box{
		display: none;
	}
	.secteur{
		img{
			display: none;
		}
		.description{
			display: none;
		}
		.btn-en-savoir-plus{
			display: none;
		}
		.openable{
			&.open{
				& + img + p + .t-item-content{
					display: block;
				}
			}
		}
		
	}
}


/*========== Mobile First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
	
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
	// DESKTOP
	section.all-sections{
		margin-right: -5px;
		margin-left: -5px;
		.cadre-secteur{
			padding: 5px;
			float: left;
			width: 50%;
		}
		.secteur{
			padding: 10px;
			
			background: white; 
			@include border-shadow(1px, 1px, 5px, #ececec);
			h2{
				background: none;
				border: none;
				text-align: center;
				&::after{
					content : '';
				}
				&.open{
					&::after{
						content : '';
					}
				}
			}
			img{
				display: block;
				max-width: 100%;

			}
			.description{
				display: block;
				min-height: 85px;
				padding-top: 5px;
			}
			.btn-en-savoir-plus{
				text-align: center;
				display: block;
				margin-left: auto;
				margin-right: auto;
				max-width: 150px;
			}
			h2{
				&.open{
					& + img + p + .t-item-content{
						display: none;
					}
				}
			}
		}
		.header-box{
			display: none;
			padding: 0 10px;
			// @include border-shadow(1px, 1px, 5px, #ececec);
			margin-bottom: 10px;
			
			h2{
				background: none;
				border: none;
				text-align: left;
				color: black;
				padding: 0;
				&::after{
					content : '';
				}
				&.open{
					&::after{
						content : '';
					}
				}
			}
			img{
				display: block;
				

			}
			.description{
				display: none;
			}
			.formations{
				display: block;
				&::after{
					clear: both;
					content: "";
					display: table;
				}
				
				.formation{
					background: none;
					border: none;
					float: left;
					min-width: 50%;
					&::after{
						display:none;
					}
					&::before{
						content: '.';
						position: absolute;
						top: 6;
						left: 0;
						font-family: $font-entypo;
					}
				}
			}
			.btn-en-savoir-plus{
				display: none;
			}
		}
	}
}
/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
	section.all-sections{
		.cadre-secteur{
			width: 33.33%;
		}
	}
}