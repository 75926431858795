section{
	&.main{
		width: 100%;

		.main-content{
			width : 100%;
			& > h1{
				margin-top: 0px;
			}
		}
		aside{
			&.main-aside{
				width: 100%;
			}
		}
	}
}
img{
	max-width: 100%;
}

/*========== Mobile First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
	
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
	// DESKTOP
	.dropdown:hover .dropdown-menu {
		display: block;
	}
	section{
		&.main{

			section{
				&.content{
					background : white;
					padding: 10px;
				}
			}
			.main-content{
				float: left;
				width : 70%;
				min-height: 1px;
				padding-right: 10px;
			}
			aside{
				&.main-aside{
					float: left;
					width: 30%;
					min-height: 1px;
					
				}
			}
		}
	}

}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
	section{
		&.main{
			.main-content{
				width : 75%;
			}
			aside{
				&.main-aside{
					width: 25%;
				}
			}
		}
	}
}
