$orange: #f86f1e;
$lightpink : #f8f3f9;
.btn{
	border-radius:0;
	&.btn-principal{
		background: $violet-principal;
		color: white;
		border: 2px solid $violet-principal;
		transition: background 0.4s linear;
		&:hover, &:active{
			color: $violet-principal;
			background: transparent;
			border-style: double;
		}
	}
	&.btn-orange{
		background: $orange;
		color: white;
		border: 2px solid $orange;
		transition: background 0.4s linear;
		&:hover, &:active{
			color: $orange;
			background: transparent;
			border-style: double;
		}
	}
	&.btn-lightpink{
		background: $lightpink;
		color: $violet-principal;
		border: 2px solid $lightpink;
		transition: background 0.4s linear;
		&:hover, &:active{
			color: $violet-principal;
			background: transparent;
			border-style: double;
		}
	}
}
.btn-more{
	width: 100%;
	padding: 5%;
	font-size: 24px;
	background: $orange;
	border: 1px solid $orange;
	color: white;
	margin: 5px 0px;
	&:hover{
		color: white;
		text-decoration: none;
	}
}
.display-inline-block{
	display: inline-block;
}
.center-block{
	margin: 0 auto;
}
.openable{
	cursor: pointer;
}
.text-underline{
	text-decoration: underline;
}
/*========== Mobile First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
	
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
	.margin-bottom-md{
		margin-bottom: 10px;
	}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}