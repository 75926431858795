$color-formation : #9c139a;
$color-secteur : #00aeef;
$color-centre : #00a651;
$nb-card : 3;
$grey: #dadada;
$darkgrey : #6e6e6e;

section{
	&.top-card{
		margin: 10px 0px;
		&:first-child{
			margin-top: 0px;
		}
		.top-card-img, .top-card-items{
			display: none;
		}
		.top-card-titre{
			width: 100%;
			height: 40px;
			padding: 8px 20px 5px 5px;
			font-size: 16px;
			position: relative;
			color: white;

			&:after{
				content:'.';
				font-family: $font-entypo;
				font-size: 20px;
				position: absolute;
				right: 5px;
				top: 5px;
			}
			a{
				color: white;
				height: 100%;
				width: 100%;
				display: block;
				&:hover{
					text-decoration: none;
				}
			}
			&.active{
				&:after{
					content:':';
				}
				& + .top-card-img{
					& + .top-card-items{
						display: block;
					}
					
				}
			}
		}
		.top-card-img{
			width: 100%;
		}
		.top-card-items{
			ul{
				list-style-type: none;
				padding-left: 5px;
				li{
					padding: 7px;
					padding-left: 25px;
					position: relative;
					font-size: 14px;
					a{
						color: $darkgrey;
					}
					&:before{
						content: '.';
						font-family: $font-entypo;
						position: absolute;
						top: 7px;
						left: 5px;
					}
				}
			}
		}
	}
}
$name-card: "formation";
$color-card: $color-formation;
@import 'card';

$name-card: "secteur";
$color-card: $color-secteur;
@import 'card';

$name-card: "centre";
$color-card: $color-centre;
@import 'card';



/*========== Mobile First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
	
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
	// DESKTOP
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
	.cards{
		margin-left: -5px;
		margin-right: -5px;
	}
	.top-cards{
		float: left;
		width : 100% / $nb-card;
		padding: 0px 5px;
	}
	section{ 
		.top-card{
			border: 1px solid $grey;

			&:hover{
				@include box-shadow(0px 4px 4px $grey);
			}
			.top-card-img, .top-card-items{
				display: block;
			}

			.top-card-img{
				width: 100%;
				padding: 10px;
			}

			.top-card-titre{
				text-align: center;
				padding: 15px;
				min-height: 75px;
				height: auto;
				font-size: 22px;
				line-height: 1;

				.titre-card-complement{
					display:block;
				}
				&:after{
					content:'';
				}

				&:active{
					&:after{
						content:'';
					}
				}
			}
		}
	}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}
