$bg : #f9f9f9;
$border : #DADADA;
section{
	.disponibilite-cours{
		padding: 7px;
		background: $bg;
		border: 1px solid $border;
		color: $violet-principal;
		i{
			margin-right: 5px;
			font-size: 16px;
			cursor: help;
		}
	}
}
.dispo{
	width: auto;
}