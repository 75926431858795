$orange: #ff9000;
$orange-text: #ffa200;
$red: #e44040;
$violet-darken: #431741;

.overlay.visible {
    display: block;
    opacity: .7;
}

.overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9000;
    display: none;
    background: #000;
}

.demande-doc-form {
    background: $violet-principal;
    padding: 10px;

    &.highlight {
        position: relative;
        z-index: 9001;
        box-shadow: 0 0 31px rgba(210, 220, 255, .8);
        border-radius: 3px;
    }

    h4 {
        color: white;
        font-size: 20px;
        margin: 0px;
        text-align: center;
    }

    .form-widget {
        max-width: 100%;
        padding: 5px 0px;
        position: relative;

        [class^="widget-"] {
            position: relative;

            .error-cross {
                top: 5px;
            }
        }

        .widget-2 {
            float: left;
            width: 50%;

            &:first-child {
                padding-right: 5px;
            }
        }

        .widget-3 {
            float: left;
            width: 33.33%;

            &:not(:last-child) {
                padding-right: 5px;
            }
        }
    }

    select, input, button {
        width: 100%;
        min-height: 30px;
        position: relative;
    }

    option {
        min-height: 25px;
    }

    select, input {
        border: 2px solid white;

        &:hover, &:focus {
            border-color: $violet-principal;
        }
    }

    select {
        &.date {
            min-width: 100%;
        }
    }

    button {
        background: $orange;
        font-size: 18px;
        color: white;
        border: 2px solid $orange;
        padding: 5px;

        i {
            vertical-align: -2px;
        }
    }

    span {
        &.error {
            color: white;
        }
    }

    .error-cross {
        position: absolute;
        right: 5px;
        top: 10px;
        font-size: 20px;
        color: $red;
    }

    p {
        &.valueHidden {
            color: $orange-text;
            font-size: 18px;
            width: 100%;
            padding-left: 20px;
            position: relative;

            &:before {
                content: '.';
                font-family: $font-entypo;
                position: absolute;
                top: 0px;
                left: 0px;
            }
        }
    }

    form {
        position: relative;
    }
}

.radio-container {
    padding: 6px;

    input[type='radio'] {
        display: none;
        float: left;
        width: 0px;
        height: 0px;
        margin-top: 0;

        & + label {
            color: white;
            display: inline-block;
            max-width: 100%;
            margin-bottom: 0;
            font-weight: normal;
            cursor: pointer;

            &:hover {
                text-shadow: 1px 1px 1px white;
            }

            .radio-custom {
                display: inline-block;
                position: relative;
                vertical-align: -5px;
                margin: 0 5px 0 0px;
                -webkit-appearance: none;
                -moz-appearance: none;
                -ms-appearance: none;
                -o-appearance: none;
                appearance: none;
                height: 20px;
                width: 20px;
                transition-duration: .2s;
                background: white;
                border: 1px solid #ccc;
                color: #fff;
            }
        }

        &:hover + label .radio-custom {
            border: 1px solid $violet-principal;
        }

        &:active + label .radio-custom,
        &:checked + label .radio-custom {
            outline: 0;
        }

        &:checked + label .radio-custom {
            &::before {
                position: absolute;
                top: 0px;
                left: 0px;
                display: inline-block;
                text-align: center;
                font-family: $font-entypo;
                font-size: 20px;
                content: "#";
                color: $violet-principal;
                line-height: 1;
            }
        }
    }
}

optgroup {
    &[label] {
        font-weight: 700;
    }
}


/*========== Mobile First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
    // DESKTOP
    .demande-doc-form {
        margin-top: -34px;
        position: relative;

        h4 {
            font-size: 28px;
        }

        &:before {
            content: "";
            height: 0px;
            width: 0;
            position: absolute;
            z-index: 10;
            top: 0;
            left: -12px;

            border: 12px solid;
            border-bottom-width: 24px;
            border-right: none;
            border-top: none;
            border-color: $violet-darken transparent;
        }

        p {
            &.valueHidden {
                font-size: 22px;
            }
        }
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
    .demande-doc-form {
        h4 {
            font-size: 34px;
        }
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}
