section{
	.breadcrumbs{
		display:none;
		min-height: 45px; 
		padding-top: 10px;
		color: white;
	}
}
// surchage bootstrap
.breadcrumb{
	margin-bottom: 0px;
	margin-top: 0px;
	background: none;
	padding-left: 0px;
	a{
		color: white;
	}
	i{
		vertical-align: -2px;
	}
	.active{
		color: white;
	}

	& > li + li:before{
		content: "";
    	padding: 0 0px;
    	color: #ccc;
	}
}

.breadcrumb > li + li:before {
    
}

/*========== Mobile First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
	
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
	// DESKTOP
	section{
		.breadcrumbs{
			display:block;
			width: 100%;
		}
	}
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}
