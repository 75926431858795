figure.logo{
	height: 40px;
	width: 200px;
	margin: 0px;
	font-family: $font-open;

	.fl-left{
		float:left;
	}
	.first{
		color: black;
		font-family: $font-fredoka;
		line-height: 9px;
		font-size: 22px;
		font-weight: bold;
	}
	mark{
		padding: 0;
	}
	.point{
		font-size: 20px;
		background: none;
		color: $violet-principal;
		display: inline;
		line-height: 50px;
	}
	.second{
		margin-left: -48px;	
		font-size: 20px;
		font-weight: 700;
	}
	.third{
		color : $violet-principal;
		margin-left: -2px;
		font-weight: 700;
	}
	.ext{
		font-size: 20px;
		color : $violet-principal;
	}
}
a.logo{
	color: inherit;
	&:hover{
		text-decoration: none;
	}
}