$bg-title: #d8c5d8;
$bg-formation : #f8f3f9;
.t-item{
	padding: 10px 0;
	.t-item-title{
		background: $violet-principal;
		color: white;
		position: relative;
		padding: 10px;
		font-size: 24px;
		border-bottom: 1px solid $violet-principal;
		margin-bottom: 0px;
		margin-top: 0px;
		transition: padding-left 0.5s ease;
		cursor: pointer;
		&:hover, &:active{
			padding-left: 15px;
		}
		&::after{
			content: '.';
			position: absolute;
			font-family: $font-entypo;
			right: 5px;
			top: 5px;
			font-size: 26px;
		}
		
	}
	.t-item-content{
		display:none;
		& > .t-item-content-list-1{
			background: $bg-formation;
			padding: 10px 15px;
			position: relative;
			color: grey;
			border-bottom: 1px solid $violet-principal;
			transition: padding-left 0.5s ease;
			margin: 0;
			&:hover, &:active{
				padding-left: 20px;
			}
			a{
				display:block;
				color: grey;
				&:hover{
					text-decoration: none;
				}
				&:active{
					outline: 0;
				}
			}
			&::after{
				content: '.';
				position: absolute;
				font-family: $font-entypo;
				right: 5px;
				top: 7px;
				font-size: 18px;
			}
		}
		
	}
	.t-item-content-2{
		display:none;
		& > .t-item-content-list-2{
			background: white;
			padding: 10px 15px;
			position: relative;
			color: $violet-principal;
			border-bottom: 1px solid $violet-principal;
			transition: padding-left 0.5s ease;
			&:hover, &:active{
				padding-left: 20px;
			}
			a{
				display:block;
				color: $violet-principal;
				&:hover{
					text-decoration: none;
				}
				&:active{
					outline: 0;
				}
			}
			&::after{
				content: '+';
				position: absolute;
				font-weight: bold;
				text-align: center;
				background: $violet-principal;
				color: white;
				right: 5px;
				top: 4px;
				font-size: 18px;
				width: 30px;
				padding: 3px 0;
				cursor: pointer;
			}
		}
		
	}
	.openable{
		&.open{
			&::after{
				content: ':';
			} 
			& + *[class*="t-item-content"]{
				display: block;
			}
		}	
	}
	.openable-item{
		cursor: pointer;
	}
	
}

/*========== Mobile First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
	
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
	// DESKTOP
	.t-item-white-md{
		.t-item-title{
			color: black;
		}
	}
	.t-item{
		padding: 0px;
	}
}
/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}