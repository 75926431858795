header{
	padding: 15px 0px;
	display : none;
}
/*========== Mobile First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
	
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
	header{
		display : block;
	}
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}
