section{
	.blog-article{
		padding-top: 10px;
		padding-bottom: 10px;
	}
}
a{
	&.article-blog-link{
		color: black;
		&:hover{
			text-decoration: none;
		}
	}
}
article{
	&.blog{
		padding: 10px 0px;
		border-top: 1px solid transparent;
		border-bottom: 1px solid transparent;
		transition: all 0.5s ease;

		h3{
			margin-top: 0px;
		}
		&:hover{
			border-top-color: grey;
			border-bottom-color: grey;
			text-decoration: none;
		}
		h5{
			font-size: 14px;
			font-weight: bold;
		}
	}

}
.content-highlight {
	margin: 20px 0 10px;
	padding: 10px;
	border: 1px solid $violet-principal;
	> p {
		margin: 0;
	}

}