$bg-nav : #f9f9f9;
$border-nav : #DADADA;
$color-link: #494949;

$bg-nav-mobile : #f4f4f4;


.navbar-header{
	button{
		background: $violet-principal;
		border-radius: 0;
		color : white;
		padding-bottom: 5px;
		margin: 5px;
		float: left;
		.titre{
			font-size: 20px;
			line-height: 0.5;
			margin-left: 10px;
		}
		.bars{
			float: left;
		}
		.icon-bar{
			background-color: white;
			border-radius : 0;
		}
	}
	
}
/*-------------------------------*/
/*           Wrappers            */
/*-------------------------------*/



.sidebar-collapse {
    z-index: 1000;
    left: 0px;
    width: 220px;
    height: 100%;
    margin-left: -220px;
    overflow-y: auto;
    overflow-x: hidden;
    background: white;
    
    @include transition(all 0.5s ease);
	position: absolute;
	border: 1px solid $violet-principal;

}



nav.toggled .sidebar-collapse {
    left: 220px;
}


nav{
	font-family: $font-open;
	border-bottom: 1px solid $border-nav;
	figure.logo{
		float: right;
		margin-top: 8px;
	}

}
ul.nav{
	margin: 0px;
	background: $bg-nav-mobile;
	a{
		color: $color-link;
		font-size: 14px;
	}
	li{
		padding-top: 5px;
		padding-bottom: 5px;
		& > a{
			i{
				color: $violet-principal;
			}
		}
	}
	
	ul{
		padding-left: 10px;
		li{
			a{
				&:before{
					content : '+';
					font-family: $font-entypo;
					position: relative;
					display: inline-block;
				}
			}
		} 
	}
}
.navbar-nav .open .dropdown-menu{
	background: white;
}



/*========== Mobile First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
	
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
	// DESKTOP
	.sidebar-collapse {
		width: $container-sm;
		margin-left: auto;
		margin-right: auto;
		// height: 30px;
		position: relative;
		border: none;
		background : none;
		overflow-y: visible;
    	overflow-x: visible;

    	& > ul{
			li{
				&:hover > ul{
					display: block;
				}
				a{
					color: $color-link;
				}
			}
			& > li{
				border-right: 1px solid $border-nav;

				&:first-child{
					border-left: 1px solid $border-nav;
				}
			}
			
		}
	}
	nav{
		background: $bg-nav;
		border-top: 1px solid $border-nav;
		font-family: $font-open;
		
		.logo{
			display: none;
		}
	}
	ul{
		&.nav{
			background:none;
			li{
				padding: 0px;
				& > a{
					i{
						color: $color-link;
					}
				}

			}

			ul{
				padding-left: 0px;
				li{
					a{
						&:before{
							content : '';
						}
					}
				} 
			}
		}

	}
	.nav-container{
		padding: 0px 15px;
	}
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {

	.sidebar-collapse {
		width: $container-md;
	}
}
/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
	.sidebar-collapse {
		width: $container-lg;
	}
}

