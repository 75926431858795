/* Common style */
.effect figure {
	position: relative;
	overflow: hidden;
	margin: 10px 1%;
	width: 100%;
	background: #3085a3;
	text-align: center;
	cursor: pointer;
}

.effect figure img {
	position: relative;
	display: block;
	max-width: 100%;
	opacity: 0.8;
}

.effect figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.effect figure figcaption::before,
.effect figure figcaption::after {
	pointer-events: none;
}

.effect figure figcaption,
.effect figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.effect figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

.effect figure h2 {
	/* word-spacing: -0.15em;*/
	font-weight: 300;
}

.effect figure h2 span {
	font-weight: 800;
}

.effect figure h2,
.effect figure p {
	margin: 0;
}

.effect figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}


@import "./effect-oscar";