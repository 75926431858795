section.formation-associe{
	h3{
		width: 100%;
		padding: 8px;
		background: $violet-principal;
		color: white;
		margin-top: 0px;
	}	
	a{
		color: black;
	}
	h6{
		font-weight: 700;
	}
	.formation{
		min-height: 200px;
		min-height: 250px;
	}
}
