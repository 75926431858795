$bg-desc : #f8f3f9;
$border : #dadada;
.t-boxes{
	.openable{
		&.open{
			& + .description {
				border-bottom: 0px;
				& + .t-item-content{
					border-left: 1px solid $border;
					border-right: 1px solid $border;
					border-bottom: 1px solid $border;
					display: block;
					.t-item-content-list-1{
						background: white;
					}
				}
			}
		}
	}
}


/*========== Mobile First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
	
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
	// DESKTOP
	.t-boxes{
		*[class*="cadre-"]{
			margin-bottom: 10px;
		}
		.secteur-sort{
			border-bottom: none !important;
			// &::after{
			// 	display: none;
			// }
		}
		.formations{
			background: white;
			.formation{
				background: none;
				float: left;
				min-width: 10%;
				padding-left: 20px;
				border-bottom: none;
				&:hover{
					&::before{
						left: 8px;
					}
				}
				&::before{
					content: ".";
					position: absolute;
					top: 10px;
					left:4px;
					font-family: $font-entypo;
					transition: left 0.5s ease;
				} 
				&::after{
					display: none;
				}
			}
			&::after{
				content: '';
				display:table;
				clear: both;
			}
		}
		.description{
			background: white;
			border: 1px solid $border;
			padding: 10px 0;
			&::after{
				content: '';
				display: table;
				clear:both;
			}
		}
	}
	

}
/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
	
}