.social-share{
	width: 100%;
	text-align: center;
	a{
		&:hover{
			text-decoration: none;
		}
	}
}
figure{
	&.reseau-social{
		display: inline-block;
		margin-right: 5px;
		img{
			max-width: 45px;
			&:hover{
				cursor: pointer;
				border-radius: 50%;
				transform: rotate(360deg);
				transition : border-radius 0.5s linear, transform 0.5s linear;
			}
		}
	}
}